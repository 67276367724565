import styled from 'styled-components';

const MapContainer = styled.div`
  overflow: hidden;
  background: none;
  height: 500px;
  width: 100%;
`;

export const GoogleMap = () => (
  <MapContainer>
    <iframe
      width="100%"
      height="100%"
      src="https://maps.google.com/maps?q=Am%20Reichenb%C3%A4chle%2053,%2079350%20Sexau&z=11&ie=UTF8&iwloc=&output=embed"
      frameBorder="0"
      scrolling="no"
    />
  </MapContainer>
);
