import styled from 'styled-components';

export const Subsection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: left;
  grid-gap: 8px;
  align-content: start;

  p {
    margin: 0;
  }
`;
