import { ReactNode } from 'react';
import styled from 'styled-components';

export const FeatureGrid = styled.div`
  
  display: flex;
  gap: 10px;
  align-content: center;
  align-items: center;
  padding-left: 20px;
  
  svg {
    width: 18px;
    height: 18px;
    vertical-align: bottom;
  }
`;

interface FeaturePros {
  icon: ReactNode;
  text: string;
}

export const Feature = ({ icon, text }: FeaturePros) => (
  <FeatureGrid>
    <>{icon}</>
    <span>{text}</span>
  </FeatureGrid>
);
