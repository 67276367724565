import SEO from '../components/seo';
import Layout from '../containers/layout';
import { Link } from 'gatsby';
import ImageGallery from 'react-image-gallery';
import { Label } from '../components/Label';
import { Box } from '../components/base/Box';
import { Title } from '../components/Title';
import { ButtonPrimary } from '../components/Button';
import { Hero } from '../components/Hero';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionGrid } from '../components/SectionGrid';
import { StatsGrid } from '../components/StatsGrid';
import {Feature, FeatureGrid} from '../components/Feature';
import { Subsection } from '../components/SubSection';
import { GoogleMap } from '../components/GoogleMap';
import { HOME } from '../constants/content';
import 'react-image-gallery/styles/css/image-gallery.css';
import { NavigationPath } from '../types/navgiation';
import { ImageGalleryContainer } from '../components/ImageGalleryContainer';
import { ContainerBodyWidth } from '../components/ContainerBodyWidth';
import { ContainerFullWidth } from '../components/ContainerFullWidth';
import HouseIcon from '@mui/icons-material/House';
import GroupsIcon from '@mui/icons-material/Groups';
import BedIcon from '@mui/icons-material/Bed';
import KitchenIcon from '@mui/icons-material/Kitchen';

const IndexPage = () => {
  return (
    <Layout currentPage="index">
      <SEO title={HOME.TITLE} description={HOME.DESCRIPTION} keywords={HOME.KEYWORDS} />

      <>
        <ContainerBodyWidth>
          <Hero>
            <Title>{HOME.HEADLINE}</Title>

            <Box display="flex" justifyContent="center">
              <Link to={NavigationPath.RESERVE}>
                <ButtonPrimary>{HOME.RESERVE_BUTTON}</ButtonPrimary>
              </Link>
            </Box>
          </Hero>
        </ContainerBodyWidth>
        <Box width="100%" maxHeight={600} display="flex" justifyContent="center">
          <StaticImage objectFit="cover" src="../images/hero.jpg" alt="abc" />
        </Box>
        <ContainerBodyWidth>
          <SectionGrid className="summary">
            <StatsGrid>
              <FeatureGrid>
                <HouseIcon />
                120 m²
              </FeatureGrid>
              <FeatureGrid>
                <GroupsIcon />
                2-5 Erwachsene
              </FeatureGrid>
              <FeatureGrid>
                <BedIcon />
                2 Schlafzimmer
              </FeatureGrid>
              <FeatureGrid>
                <KitchenIcon />
                <span>Moderne Küche</span>
              </FeatureGrid>
            </StatsGrid>

            <Subsection>
              <Label>FERIENWOHNUNG</Label>
              <p>
                Idyllisch gelegene Ferienwohnung in schönem Fachwerkhaus.
              </p>

              <ul>
                <li>Offener Küchen-, Ess- & Wohnbereich</li>
                <li>2 Schlafzimmer</li>
                <li>Bad mit Badewanne und Dusche</li>
                <li>Separates WC</li>
                <li>Großzügige überdachte Terasse mit Grillmöglichkeit</li>
                <li>Hunde auf Anfrage möglich</li>
                <li>Kinderbettchen und Hochstuhl kann gestellt werden.</li>
                <li>Freizeitkarte in der Hochschwarzwaldkarte inkludiert</li>
              </ul>
              <br/>


              <Label>UMGEBUNG</Label>
              <ul>
                <li>Viele Wander- und Ausflugsmöglichkeiten.
                </li>
                <li>20km von Freiburg entfernt</li>
                <li>4km von Emmendingen entfernt</li>
                <li>Ca. 1h ins Elsass oder an die Schweizer Grenze</li>
              </ul>
            </Subsection>
          </SectionGrid>
        </ContainerBodyWidth>
        <ContainerFullWidth className="secondary">
          <ContainerBodyWidth>
            <ImageGalleryContainer>
              <ImageGallery
                items={[
                  { original: '/images/kueche2.jpg' },
                  { original: '/images/schlafzimmer.jpg' },
                  { original: '/images/garten.jpg' },
                ]}
                showBullets={true}
                thumbnailPosition={'bottom'}
                showPlayButton={false}
                showIndex={false}
                showFullscreenButton={false}
                lazyLoad={true}
              />
            </ImageGalleryContainer>
            <Box display="flex" justifyContent="center" p={3} pb={5}>
              <Link to={NavigationPath.PHOTOS}>
                <ButtonPrimary>{HOME.MORE_PHOTOS_BUTTON}</ButtonPrimary>
              </Link>
            </Box>
          </ContainerBodyWidth>
        </ContainerFullWidth>
        <ContainerFullWidth>
          <Box display="flex" justifyContent="center" p={1}>
            <h2>Anfahrt</h2>
          </Box>
          <GoogleMap />
        </ContainerFullWidth>
      </>
    </Layout>
  );
};

export default IndexPage;
