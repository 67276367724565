import styled from 'styled-components';
import Image from 'gatsby-image';
import { StaticImage } from 'gatsby-plugin-image';

export const Hero = styled.div`
  display: grid;
  grid-gap: 30px;
  background-color: ${props => props.theme.bg.primary};
  margin: 0px auto 30px;
  padding: 30px 0 0 0;

  @media (min-width: 600px) {
    margin: 125px auto 30px;
  }
`;

export const HeroContainer = styled.div`
  position: relative;

  svg {
    width: 100%;
  }
`;

export const HeroImage = styled(StaticImage)`
  height: 600px;

  @media (max-width: 600px) {
    height: 300px;
  }
`;
