import styled from 'styled-components';

export const SectionGrid = styled.div`
  display: grid;
  margin: 40px 0;
  padding: 0 16px;
  grid-template-columns: 1fr;
  align-content: start;

  grid-gap: 40px;
  width: 100%;
  box-sizing: border-box;

  p {
    margin: 5px 0 0 0;
  }

  &.reviews {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  &.summary {
    grid-gap: 40px;

    @media (min-width: 600px) {
      grid-template-columns: auto auto;
      grid-gap: 20px;
    }
  }

  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;

    &.amenities {
      grid-template-columns: auto 1fr;
    }
  }
`;
