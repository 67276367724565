import styled from 'styled-components';

export const ImageGalleryContainer = styled.div`
  margin: 0 0 0 0;
  padding: 40px 0;

  .image-gallery-image {
    height: 100vw;
    object-fit: cover;
  }

  .image-gallery-thumbnail-image {
    object-fit: cover;
    height: 50px;
  }

  @media (min-width: 600px) {
    .image-gallery-image {
      height: 700px;
    }
  }
`;
