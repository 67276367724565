import styled from 'styled-components';
import {
  space,
  color,
  layout,
  flexbox,
  typography,
  ColorProps,
  SpaceProps,
  LayoutProps,
  FlexboxProps,
} from 'styled-system';

type Props = ColorProps & SpaceProps & LayoutProps & FlexboxProps;

export const Box = styled.div<Props>(space, color, layout, flexbox);
