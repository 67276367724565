import styled from 'styled-components';

export const StatsGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
  max-width: 800px;
  padding-left: 20px;

  @media (min-width: 600px) {
    padding: 0px 20px 0px 0px;
    border-right: 1px solid ${props => props.theme.border.secondary};
  }
`;
