import styled from 'styled-components';

export const Label = styled.h3`
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin: 0 0 0 0;
  color: ${props => props.theme.text.secondary};
`;
